












































import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import List from "../../components/ShareComponent/PullDownUp.vue";
import UpDownCom from "../../components/ShareComponent/ListBottom.vue";
import { Notify } from "vant";
import Stroage from "../../util/Storage";
import { IsPageNo } from "../../util/filter";
import { HomeStore } from "../../store/modules/Home";
import { GetNotice } from "../../Api/Basics/index";
import { NoticeType } from "../../Type/index";
import { Vue, Component } from "vue-property-decorator";
import ZoomPage from "@/impView/PageSafety";

interface InformListType<T = string> {
  userId: T | number;
  token: T;
  timeIcon: T;
  loadingShow: boolean;
  UpData: {
    type: number;
    pageNo: number;
    pageSize: number;
  };
  List: NoticeType[];
  PullDown: boolean;
  UpDown: boolean;
  UpDownBool: boolean;

  handleToPath(data: NoticeType): void;
  handlePullDown(data: any): void;
  handleUpDown(data: any): void;
  handleUpData(bool?: boolean): void;
  handleError(data: string): void;
}

@Component({ name: "InformList", components: { List, UpDownCom,HeadTop } })
export default class InformList extends ZoomPage implements InformListType {
  userId: string | number = "";
  token = "";
  timeIcon = require("$icon/Basics/time.png");
  loadingShow = true;
  UpData = {
    type: 0,
    pageNo: 1,
    pageSize: 15,
  };
  List: NoticeType[] = [];
  PullDown = false;
  UpDown = false;
  UpDownBool = false;

  mounted() {
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId");
    this.token = Stroage.GetData_ && Stroage.GetData_("token");

    if (HomeStore.GetNoticeList.length) {
      this.loadingShow = false;
      this.UpData.pageNo = IsPageNo(HomeStore.GetNoticeList.length, this.UpData.pageSize);
      this.List = HomeStore.GetNoticeList;
    } else {
      this.handleUpData();
    }
  }

  handleToPath(data: NoticeType) {
    this.$router.push({
      path: "/informSuc",
      query: {
        id: data.id as string,
      },
    });
  }

  handlePullDown(data?: any) {
    this.PullDown = false;
    this.UpData.pageNo = 1;
    GetNotice(
      {
        userId: this.userId,
        token: this.token,
      },
      this.UpData
    ).then((res: any) => {
      this.PullDown = true;
      if (res.message.code === "200") {
        if (res.data.list.length < this.UpData.pageSize) {
          this.UpDownBool = true;
        } else {
          this.UpData.pageNo += 1;
        }
        this.List = res.data.list;
        HomeStore.SetNoticeList(this.List);
      } else {
        this.handleError(res.message.msg);
      }
    });
  }

  handleUpDown(data?: any) {
    if (this.UpDownBool) return;
    this.UpData.pageNo += 1;
    this.handleUpData();
  }

  handleUpData(bool?: boolean): void {
    this.UpDown = true;
    GetNotice(
      {
        userId: this.userId,
        token: this.token,
      },
      this.UpData
    ).then((res: any) => {
      this.loadingShow = false;
      this.UpDown = false;
      if (res.message.code === "200") {
        if (res.data.list.length < this.UpData.pageSize) {
          this.UpDownBool = true;
        }
        if (bool) {
          this.List = res.data.list;
        } else {
          this.List = this.List.concat(res.data.list);
        }
        HomeStore.SetNoticeList(this.List);
      } else {
        this.handleError(res.message.msg);
      }
    });
  }

  handleError(data: string) {
    let str = "";
    if (data.length) {
      str = data;
    } else {
      str = "网络繁忙...";
    }
    Notify({
      message: str,
      color: "white",
      background: "rgba(0,0,0,.6)",
    });
  }
}
